import React from "react"
import lottie from "lottie-web"

import "./LoadingAnimationSection.scss"

const LoadingAnimationSection = () => {
  React.useEffect(() => {
    const addLogo = () => {
      const logo = document.querySelector(
        ".maven-home-header__logo__image-before"
      )
      logo.classList.add("maven-home-header__logo__image")
    }

    lottie.loadAnimation({
      container: document.getElementById("svgContainer"),
      renderer: "svg",
      loop: false,
      autoplay: true,
      path:
        window.innerWidth > 900
          ? "/animation/desktop.json"
          : "/animation/mobile.json",
      onComplete: addLogo,
    })
  }, [])

  return (
    <section className="loading-animation-section">
      <div className="loading-animation-section__container">
        <div id="svgContainer"></div>
      </div>
    </section>
  )
}

export default LoadingAnimationSection
