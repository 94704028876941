import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import he from "he"

import SEO from "../components/seo"

import LoadingAnimationSection from "../components/LoadingAnimationSection"
import Header from "../components/maven-home/maven-home-header/MavenHomeHeader"
import MavenHome from "../components/maven-home/MavenHome"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      wpPage(slug: { eq: "maven-home" }) {
        seo {
          title
          metaDesc
        }
        acf {
          contentBlocks {
            ... on WpPage_Acf_ContentBlocks_BackgroundImage {
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            ... on WpPage_Acf_ContentBlocks_Title {
              title
            }
          }
        }
      }
    }
  `)

  return (
    <div>
      <SEO
        path="/"
        title={he.decode(data.wpPage.seo.title)}
        description={data.wpPage.seo.metaDesc}
      />
      <Header />
      <LoadingAnimationSection />
      <MavenHome data={data} />
    </div>
  )
}

export default IndexPage
