import React from "react"
import BackgroundImage from "gatsby-background-image"
import PropTypes from "prop-types"

import "./MavenHome.scss"

const MavenHome = (props) => {
  return (
    <>
      <BackgroundImage
        className="maven-home maven-home--mobile"
        fluid={
          props.data.wpPage.acf.contentBlocks[1].image.localFile.childImageSharp
            .fluid
        }
        style={{
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "100% 100%",
        }}
      >
        <h1 className="maven-home__intro">
          {props.data.wpPage.acf.contentBlocks[3].title}
        </h1>
        <h1 className="maven-home__intro">
          {props.data.wpPage.acf.contentBlocks[4].title}
        </h1>
      </BackgroundImage>
      <BackgroundImage
        className="maven-home maven-home--desktop"
        fluid={
          props.data.wpPage.acf.contentBlocks[2].image.localFile.childImageSharp
            .fluid
        }
        style={{
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "100% 100%",
        }}
      >
        <h1 className="maven-home__intro">
          {props.data.wpPage.acf.contentBlocks[3].title}
        </h1>
        <h1 className="maven-home__intro">
          {props.data.wpPage.acf.contentBlocks[4].title}
        </h1>
      </BackgroundImage>
    </>
  )
}

MavenHome.propTypes = {
  data: PropTypes.any,
}

export default MavenHome
