import React, { useState } from "react"

import Link from "../../../utils/link"
import HeaderLinks from "../../header-links/HeaderLinks"

import MavenLogo from "../../../assets/images/MavenLogo.svg"

import "./MavenHomeHeader.scss"

const MavenHomeHeader = () => {
  const [show, setShow] = useState(false)

  const toggler = () => {
    setShow(!show)
  }

  let btnClass = "maven-home-header__menuBtn"
  let linksClass = "maven-home-header__links"
  if (show) {
    btnClass = "maven-home-header__menuBtn maven-home-header__menuBtn--active"
    linksClass = "maven-home-header__links maven-home-header__links--active"
  }

  return (
    <>
      <div className="maven-home-header">
        <div className="maven-home-header__container">
          <div className="maven-home-header__logo">
            <Link to="/" title="Home">
              <img
                src={MavenLogo}
                className="maven-home-header__logo__image-before maven-home-header__logo__image"
              />
            </Link>
          </div>
          <div className="maven-home-header__navigation">
            <div className="maven-home-header__menu">
              <button onClick={toggler} className={btnClass}>
                /
              </button>
            </div>
            <div className={linksClass}>
              <HeaderLinks />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MavenHomeHeader
